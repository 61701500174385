import React from 'react'

import styles from './footer.module.css'

const Footer = () => {
  // const copyPhrase = `© ${new Date().getFullYear()}, Built with `
  // const byPhrase = ' by '
  return (
    <footer className={styles.footer}>
      {/* © 2020, Built with Gatsby by Omar Houmz */}
      {/* https://github.com/omhoumz/altessecake */}
    </footer>
  )
}

export default Footer
